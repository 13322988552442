<template>
  <div class="container p-2">
    <div>
      <div class="col">
        <form @submit.prevent="validateCashAccount" ref="formStepOne" v-if="stepOne">
          <h2>Étape 1/2</h2>
          <div class="mb-3" v-if="!preSelect">
            <label for="eventSelected" class="form-label">Sélectionnez l'évènement *</label>
            <select name="eventSelected" v-model="eventId" required class="form-select" @change="selectCashEvent">
              <option v-for="(event, i) in events" v-bind:key="i" :value="event.id">{{ event.name }}</option>
            </select>
          </div>

          <div v-if="selectedCashEvent">
						<h3>{{selectedCashEvent.name}}</h3>
						<div v-if="!expiredDate">
							<p>Renseignez le numéro de téléphone utilisé lors de la création ou le numéro indiqué sur la carte *</p>
							<p>Si jamais votre carte a été bloquée, le numéro de téléphone est obligatoire pour demander le remboursement</p>
							<div class="row flex-column flex-lg-row align-items-center">
								<div class="col mb-3">
									<label for="tel" class="form-label">Numéro de téléphone</label>
									<vue-tel-input :class="stepOneIsValid ? 'form-control is-valid' : 'form-control is-invalid'" v-model="dataUser.phoneNumberInput" mode="international" :inputOptions="options" defaultCountry="FR" :dropdownOptions="dropdown" @validate="onValidatePhone" @change="validateStepOne" style="max-height: 39px;" />
								</div>
								<div class="col-1 mt-lg-4">
									<p style="text-align: center">OU</p>
								</div>
								<div class="col mb-3">
									<div>
										<label for="supportDisplayIdentifier" class="form-label">Numéro de la carte Cashless</label>
										<input type="text" name="supportDisplayIdentifier" v-model="dataUser.supportDisplayIdentifier" :class="stepOneIsValid ? 'form-control is-valid' : 'form-control is-invalid'" @change="validateStepOne">
									</div>
									<div>
										<label for="barcodeIdentifier" class="form-label">Identifiant du code barre</label>
										<input type="text" name="barcodeIdentifier" v-model="dataUser.barcodeIdentifier" :class="stepOneIsValid ? 'form-control is-valid' : 'form-control is-invalid'" @change="validateStepOne">
									</div>
								</div>
							</div>
							<br/>

							<input class="btn btn-outline-primary w-100" v-if="cashAccounts.length==0" type="button" value="Valider" @click="validateCashAccount"/>
							<div v-if="cashAccounts.length>0">
								<div class="col-lg-12">
									<div v-if="cashAccounts.length>1">{{ cashAccounts.length }} cartes trouvées</div>
									<div v-else>{{ cashAccounts.length }} carte trouvée</div>
									<div class="table-responsive">
										<table class="table">
											<thead>
												<tr>
													<th scope="col">Carte</th>
													<th scope="col">Solde</th>
													<th scope="col">Actions</th>
													<th scope="col" v-if="selectedCashEvent.cashlessReloadOnline"></th>
												</tr>
											</thead>
											<tr v-for="cashAccount in cashAccounts" :key="cashAccount.id">
													<th scope="row">{{ cashAccount.supportDisplayIdentifer }}</th>
													<td>{{ cashAccount.balance }} €</td>
													<td><input class="btn btn-outline-primary" type="button" value="Voir l'historique" @click="goToHistoricScreen(cashAccount.id)"></td>
													<td v-if="selectedCashEvent.cashlessReloadOnline"><input class="btn btn-outline-primary" type="button" value="Recharger" @click="goToReloadCardScreen(cashAccount.id)"></td>
											</tr>
										</table>
									</div>
								</div>

								<br/>

								<div v-if="selectedCashEvent.refundDateBegin!=null && selectedCashEvent.refundDateEnd!=null">
									<p v-if="!selectedCashEvent.validPeriodForRefund || expiredDate" class="text-danger">Remboursement du {{ formatDate(selectedCashEvent.refundDateBegin, 'DD/MM/YYYY') }} au {{ formatDate(selectedCashEvent.refundDateEnd, 'DD/MM/YYYY') }}</p>
									<p v-if="selectedCashEvent.validPeriodForRefund && !expiredDate" >Remboursement du {{ formatDate(selectedCashEvent.refundDateBegin, 'DD/MM/YYYY') }} au {{ formatDate(selectedCashEvent.refundDateEnd, 'DD/MM/YYYY') }}</p>

									<div class="row flex-column flex-lg-row">
										<div class="col mb-2">
											<p v-if="minimumBalanceNotEnough">Le minimum est de {{ selectedCashEvent.refundMinimumBalance }} € pour obtenir un remboursement</p>
											<input :class="!canRefund ? 'btn-secondary btn btn-primary w-100' : 'btn btn-primary w-100'" type="button" value="Demande de remboursement" :disabled="!canRefund" @click="goToRefundScreen">
										</div>
										<div class="col">
										</div>
									</div>
								</div>
							</div>
						</div> <!-- Fin expired date -->
          </div>
        </form>
        <form @submit.prevent="onSubmit" ref="formStepTwo" v-if="refundScreen">
          <h2>Étape 2/2</h2>
          <div class="row flex-column flex-lg-row">
			<div class="col mb-3">
				<label for="email" class="form-label">Email</label>
				<input type="text" name="email" v-model="dataUser.email" class="form-control">
			</div>
          </div>
          <div class="row flex-column flex-lg-row">
			<div class="col mb-3">
				<label for="ibanName" class="form-label">Nom du bénéficiaire *</label>
				<input type="text" name="ibanName" v-model="dataUser.ibanName" required class="form-control">
			</div>
          </div>
          <div class="row flex-column flex-lg-row">
            <div class="col-lg-8 mb-3">
              <label for="iban" class="form-label">IBAN *</label>
              <input type="text" name="iban" v-model.trim="dataUser.ibanCode" required class="form-control"  @input="removeSpaces" @change="removeSpaces">
              <span v-if="!isValidIBAN(this.dataUser.ibanCode) && this.dataUser.ibanCode != ''">Iban invalide</span>
              <p v-if="!isValidIBAN(this.dataUser.ibanCode) && this.dataUser.ibanCode != ''" class="text-warning">Attention, sur iPhone et certains Samsung, le copier coller de l'IBAN ne fonctionne pas toujours, merci de vérifier que vous avez bien 24 caractères + 3 autres pour la clé de sécurité</p>
            </div>
            <div class="col-lg-4 mb-3">
              <label for="swift" class="form-label">Code BIC/Swift *</label>
              <input type="text" name="swift" v-model="dataUser.ibanSwiftCode" required class="form-control uppercase">
              <span v-if="!isValidBIC(this.dataUser.ibanSwiftCode) && this.dataUser.ibanSwiftCode != null">Code BIC/Swift invalide</span>
            </div>
          </div>
          <div class="row flex-column flex-lg-row gap-4 mt-2">
            <div class="col">
              <input class="btn btn-outline-primary w-100" type="button" value="Retour" @click="back">
            </div>
            <div class="col mb-2">
              <input class="btn btn-primary w-100" type="submit" value="Valider">
            </div>
          </div>
        </form>
      </div>
      <div v-if="refundScreen2">
        <h2>Demande envoyée</h2>
        <p>Votre demande de remboursement a été prise en compte.</p>
        <p>Le virement sera effectué à la fin de la période de remboursement le {{ formatDate(selectedCashEvent.refundDateEnd, 'DD MMMM YYYY') }}</p>
      </div>
      <div v-if="historicScreen">
        <h2>Historique</h2>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Point de Vente</th>
              <th scope="col">Numéro de commande</th>
              <th scope="col">Montant</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody v-if="cashOrderHistory.length > 0">
            <tr v-for="(order, i) in cashOrderHistory" v-bind:key="i">
              <td>{{order.salePlaceName}}</td>
              <th scope="row">{{order.orderNumber}}</th>
              <td>{{order.totalTax + "€"}}</td>
              <td>{{formatDate(order.date, 'YYYY-MM-DD HH:mm')}}</td>
            </tr>
          </tbody>
          <div v-else>
            <p>Vous n'avez pas effectué de commandes.</p>
          </div>
        </table>
        <button class="btn btn-outline-primary" type="button" @click.prevent="back">Retour</button>
      </div>
			<ReloadCardModal v-if="reloadCardScreen" @back="back" @close="closeReloadCardModal" :cash-account-id="cashAccountIdSelected" :cash-event-id="eventId"/>
      <br/>
      <p class="text-center mt-2">Solution proposée par <a href="https://www.tropevent.com" target="_blank">Tropevent.com</a></p>
      <!--
      <p>En cas de problèmes, contactez-nous à cette adresse <a href="mailto:hello@tropevent.com">hello@tropevent.com</a></p>
      <p>Afin de faciliter la résolution du problème, <b>merci d'indiquer les informations utilisées lors de la création de la carte : Nom, Numéro de téléphone et numéro de la carte si possible.</b></p>
       -->
    </div>
  </div>
</template>

<script>
import {isValidBIC, isValidIBAN} from "ibantools";
import {mapActions} from "vuex";
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Importez également la localisation française
import LogRocket from 'logrocket';
import ReloadCardModal from "@/views/modals/ReloadCardModal.vue";

export default {
  name: "EmbedPage",
  components: {
		ReloadCardModal,
    VueTelInput,
  },
  data() {
    return {
      eventId: null,
      selectedCashEvent: null,
      preSelect: false,
      dataUser: {
        id: null,
				lastName: '',
				firstName: '',
        countryCode: null,
        phoneNumberInput: '',
        phoneNumber: '',
        supportDisplayIdentifier: '',
				barcodeIdentifier: '',
        cashAccountId: null,
        email: null,
        ibanCode: '',
        ibanSwiftCode: '',
        ibanName: '',
        balance: null,
				reloadAmount: null
      },
      cashAccounts: [],
      stepOne: true,
      stepOneIsValid: null,
      refundScreen: false,
      refundScreen2: false,
      historicScreen: false,
			reloadCardScreen: false,
			reloadStepIsValid: false,
      phoneNumberIsValid: null,
      cashAccountSelected: null,
			cashAccountIdSelected: null,
      canRefund: false,
      cardBlocked: false,
      minimumBalanceNotEnough: false,
      expiredDate:false,
      options: { placeholder: 'Numéro de téléphone' },
      dropdown: {
        showDialCodeInList: true,
        showDialCodeInSelection: true,
        showFlags: true,
        showSearchBox: true
      },
      cashOrderHistory: []
    }
  },
  computed: {
    events() {
      return this.$store.getters.events
    }
  },
  methods: {
    selectCashEvent() {
      this.expiredDate=false;
      this.selectedCashEvent= this.events.filter(e => e.id === this.eventId)[0];
      if(this.selectedCashEvent && this.selectedCashEvent.refundDateEnd && new Date(Date.parse(this.selectedCashEvent.refundDateEnd))<new Date()){
        this.expiredDate=true;
      }
    },
    isValidBIC,
    isValidIBAN,
    ...mapActions(['getEvents']),
    back() {
      this.stepOne = true
      this.refundScreen = false
      this.historicScreen = false
			this.reloadCardScreen = false
			this.cashAccountIdSelected = null
    },
    goToRefundScreen() {
      this.stepOne = false
      this.refundScreen = true
    },
    goToHistoricScreen(cashAccountId) {
      this.$store.dispatch('getCashOrderHistoryByCashAccount', cashAccountId).then((response) => {
        this.cashOrderHistory = response.data
        this.stepOne = false
        this.historicScreen = true
      })
    },
		goToReloadCardScreen(cashAccountId) {
			this.reloadCardScreen = true
			this.cashAccountIdSelected = cashAccountId
		},
		closeReloadCardModal() {
			this.reloadCardScreen = false
			this.cashAccountIdSelected = null
		},
    onValidatePhone(e) {
      if (e.valid) {
        this.dataUser.countryCode = e.countryCode;
        this.dataUser.phoneNumberInput = e.nationalNumber;
        this.dataUser.phoneNumber = e.number;
        this.phoneNumberIsValid = true;
      } else {
        this.dataUser.phoneNumber = null;
        this.phoneNumberIsValid = false;
      }
      this.validateStepOne();
    },
    isIOS() {
        var platform = navigator?.userAgent || navigator?.platform || 'unknown';
        return /iPhone|iPod|iPad/.test(platform);
    },
    validateStepOne() {
      this.dataUser.supportDisplayIdentifier = this.dataUser.supportDisplayIdentifier.toUpperCase();
      this.stepOneIsValid = (this.dataUser.phoneNumber !== '' && this.phoneNumberIsValid) || this.dataUser.supportDisplayIdentifier !== '' || this.dataUser.cashAccountId !== null || this.dataUser.barcodeIdentifier !== '';
    },
    validateCashAccount() {
      if (this.eventId != null && (this.stepOneIsValid)) {
        // On vérifie si le numéro de téléphone est dans la liste des cashAccount

        var search ={
          eventId: this.eventId,
          cashAccountId: this.dataUser.cashAccountId,
          phoneNumber: this.dataUser.phoneNumber,
          supportDisplayIdentifier: this.dataUser.supportDisplayIdentifier,
					barcodeIdentifier: this.dataUser.barcodeIdentifier
        }
        this.$store.dispatch('findCashAccount', search).then((response) => {
            this.cashAccounts=response.data;


          this.cardBlocked = false;
          this.minimumBalanceNotEnough=false;
          if (this.cashAccounts.length>0) {
            this.cashAccountSelected = true
            // On update le cashOrderHistory
            // On vérifie si le cashAccount peut être remboursé (balance > 0 || dateRemboursement < dateNow || iban is null)
            if(this.selectedCashEvent.validPeriodForRefund){
              this.canRefund = true;
            }else{
              this.canRefund = false;
            }
            this.cashAccounts.forEach(cashAccount => {
              if(cashAccount.balance<this.selectedCashEvent.refundMinimumBalance){
                this.minimumBalanceNotEnough=true;
                this.canRefund = false;
              }
              if(cashAccount.cardStatus && cashAccount.cardStatus!='LINKED'){
                  this.canRefund = false;
                  this.cardBlocked= true;
              }
            })

          } else {
            this.$notify({
              title: 'Compte non trouvé',
              text: 'Veuillez réessayer avec un autre numéro de téléphone ou numéro de carte.',
              type: 'error'
            });
            this.dataUser.phoneNumber = '';
            this.dataUser.supportDisplayIdentifier = '';
          }
          if(this.cardBlocked){
              this.dataUser.supportDisplayIdentifier='';
              this.$notify({
                title: 'La carte a été bloquée',
                text: 'Veuillez indiquer le numéro de téléphone utilisé lors de la création ou nous contacter',
                type: 'error'
              });
              this.cashAccounts=[];
          }

        }).catch(() => {
          this.$notify({
            title: 'Une erreur est survenue',
            text: 'Veuillez réessayer ultérieurement.',
            type: 'error'
          });
        })
      }
    },
      onSubmit() {
        this.removeSpaces();
        if (isValidIBAN(this.dataUser.ibanCode) && isValidBIC(this.dataUser.ibanSwiftCode)) {
            // On update le cashAccount avec le bénéficiaire, l'iban et le swift
            let refundDTO ={
              eventId: this.eventId,
              phoneNumber: this.dataUser.phoneNumber,
              supportDisplayIdentifier: this.dataUser.supportDisplayIdentifier,
              email: this.dataUser.email,
              ibanName: this.dataUser.ibanName,
              ibanCode: this.dataUser.ibanCode,
              ibanSwiftCode: this.dataUser.ibanSwiftCode
            };

            this.$store.dispatch('request', refundDTO)
              .then((response) => {
                this.dataUser = response.data
                this.refundScreen = false
                this.refundScreen2 = true
                if (refundDTO.email != null && refundDTO.email !== '') {
                  this.$store.dispatch('sendMail', refundDTO)
                }
              }).catch((error) => {
                if(error.response.data.error && error.response.data.error == 'CASH_ACCOUNT_REFUND_ALREADY_REQUEST'){
                  this.$notify({
                    title: 'Demande de remboursement déjà effectuée',
                    text: 'Une demande de remboursement a déjà été enregistré pour ce compte cashless',
                    type: 'error'
                  });
                }else{
                  this.$notify({
                    title: 'Une erreur est survenue',
                    text: 'Vous ne pouvez pas demander un remboursement.',
                    type: 'error'
                  });
                }
              })
        } else {
          this.$notify({
            title: 'Champs manquants',
            text: 'Veuillez remplir l\'IBAN ainsi que son code Swift/BIC.',
            type: 'error'
          });
        }
    },
    embedded() {
      if (window.self !== window.top) {
        setTimeout(() => {
          window.parent.postMessage(document.body.scrollHeight, '*');
        }, 0)
      }
    },
    formatDate(date, formatPattern) {
      if(date){
        return dayjs(date).locale('fr').format(formatPattern);
      }else{
        return '';
      }
    },
		removeSpaces() {
			if(this.dataUser.ibanCode){
				this.dataUser.ibanCode=this.dataUser.ibanCode.replace(/\s/g, '').toUpperCase();
			}
		},
		normalizePhoneNumber(phoneNumber) {
			// Remplacer les espaces par des plus
			return phoneNumber.replace(/ /g, '+');
		},
		checkParamsURI() {
			if (this.$route.query.cashEventId != null) {
				// L'url indique un cashEventId a pré-sélectionner par défaut
				this.eventId = parseInt(this.$route.query.cashEventId)
				setTimeout(() => {
					// Récupérer les informations de l'évènement pour afficher le nom
					this.selectCashEvent();
					if (this.selectedCashEvent === undefined) {
						return this.$notify({
							title: 'Évènement non trouvé',
							text: 'L\'évènement sélectionné n\'existe pas.',
							type: 'error'
						});
					}
					this.preSelect = true;
				}, 300)
			}
			if (this.$route.query.cashAccountId != null && this.$route.query.cashAccountId != '') {
				setTimeout(() => {
					this.dataUser.cashAccountId = this.$route.query.cashAccountId;
					this.stepOneIsValid = true;
					this.validateCashAccount();
				}, 300)
			}
			if (this.$route.query.paymentStatus != null) {
				setTimeout(() => {
					if (this.$route.query.paymentStatus === 'SUCCEEDED') {
						this.$notify({
							title: 'Paiement effectué',
							text: 'Votre paiement a été effectué avec succès.',
							type: 'success'
						});
					} else if (this.$route.query.paymentStatus === 'FAILED' || this.$route.query.paymentStatus === 'CANCELED') {
						this.$notify({
							title: 'Erreur de paiement',
							text: 'Une erreur est survenue lors du paiement.',
							type: 'error'
						});
					}
				}, 300)
			}
		}
  },
  mounted() {
	LogRocket.init('tropevent-cash-public/tropevent-cash-public');
    this.getEvents();
    this.embedded();
		this.checkParamsURI();
  },
  updated() {
    this.embedded();
  }
}
</script>

<style>
.container {
  overflow: hidden;
}
.uppercase {
  text-transform: uppercase;
}
</style>
